import React from 'react'
import { ITranslationContext, TranslationContext } from './src/context/TranslationContext'

const wrapPageElement = ({ element, props }) => {
    const ctx: ITranslationContext = {
        language: 'en',
        translationTable: {},
    }

    if (props.pageContext.langTable) {
        ctx.translationTable = props.pageContext.langTable
    }

    if (props.pageContext && props.pageContext.language) {
        ctx.language = props.pageContext.language
    }

    return <TranslationContext.Provider value={ctx}>{element}</TranslationContext.Provider>
}

export default wrapPageElement
