import React from 'react'
const contextProps: IGlobalState = { username: '', loggedIn: false }
const dispatchProps: IGlobalDispatch = { dispatch: () => null }

export const GlobalStateContext = React.createContext(contextProps)
export const GlobalDispatchContext = React.createContext(dispatchProps)

interface IGlobalState {
    username: string
    loggedIn: boolean
}

interface IGlobalDispatch {
    dispatch: React.Dispatch<any>
}

const initialState: IGlobalState = {
    username: '',
    loggedIn: false,
}

function reducer(state: IGlobalState, action: any) {
    switch (action.type) {
        case 'login':
            const { username } = action.payload

            return {
                ...state,
                username,
                loggedIn: true,
            }

        case 'logout':
            return {
                ...initialState,
            }

        default:
            throw new Error('Bad Action Type')
    }
}

const GlobalContextProvider = (props: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={{ dispatch }}>{props.children}</GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider
