// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-success-story-page-template-tsx": () => import("./../src/templates/SuccessStoryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-success-story-page-template-tsx" */),
  "component---src-templates-root-page-template-tsx": () => import("./../src/templates/RootPageTemplate.tsx" /* webpackChunkName: "component---src-templates-root-page-template-tsx" */),
  "component---src-templates-product-eval-page-template-tsx": () => import("./../src/templates/ProductEvalPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-eval-page-template-tsx" */),
  "component---src-templates-product-eval-download-page-template-tsx": () => import("./../src/templates/ProductEvalDownloadPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-eval-download-page-template-tsx" */),
  "component---src-templates-samples-page-template-tsx": () => import("./../src/templates/SamplesPageTemplate.tsx" /* webpackChunkName: "component---src-templates-samples-page-template-tsx" */),
  "component---src-templates-quote-request-page-template-tsx": () => import("./../src/templates/QuoteRequestPageTemplate.tsx" /* webpackChunkName: "component---src-templates-quote-request-page-template-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-default-page-template-tsx": () => import("./../src/templates/DefaultPageTemplate.tsx" /* webpackChunkName: "component---src-templates-default-page-template-tsx" */),
  "component---src-templates-publication-list-tsx": () => import("./../src/templates/PublicationList.tsx" /* webpackChunkName: "component---src-templates-publication-list-tsx" */),
  "component---src-templates-my-pdf-tools-page-template-tsx": () => import("./../src/templates/MyPDFToolsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-my-pdf-tools-page-template-tsx" */),
  "component---src-templates-publication-page-template-tsx": () => import("./../src/templates/PublicationPageTemplate.tsx" /* webpackChunkName: "component---src-templates-publication-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

