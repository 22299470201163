import * as React from 'react'

import { ITranslationCategory } from '../helper/TranslationHelper'
import { LangKey } from '../types/Languages'

export interface ITranslationContext {
    language: LangKey
    translationTable: ITranslationCategory
}

const ctx: ITranslationContext = { language: 'en', translationTable: { en: {}, de: {}, fr: {} } }

export const TranslationContext = React.createContext(ctx)
